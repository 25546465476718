.thisweek-sessions-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #456673;
  margin-bottom: 5px;
  border-left: 7px solid #2ecb6f;
}

.thisweek-sessions-card:hover {
  background-color: #ebe3e4;
}

.thisweek-session-cardtitle {
  display: flex;
  justify-content: center;
  font-weight: bolder;
  font-size: small;
  margin: 2.5px 0px;
  cursor: pointer;
}

.thisweek-session-cardtitle:hover {
  text-decoration: underline;
}

.thisweek-session-ioncardcontent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.thisweek-session-membername {
  display: flex;
  justify-content: center;
  font-weight: bolder;
  font-size: small;
  cursor: pointer;
}

.thisweek-session-membername:hover {
  text-decoration: underline;
}

.thisweek-sessions-card.microsoft-teams {
  border-left: 7px solid #505ac9;
}

.thisweek-sessions-card.allied-wellness-app {
  border-left: 7px solid #243841;
}

.thisweek-session-cancelled {
  display: flex;
  justify-content: center;
  font-size: small;
  font-weight: bolder;
  padding: 2px;
  color: white;
  background-color: red;
  border-radius: 1px;
}
