.dashboard-photo {
  border-radius: 150%;
  width: 200px;
  height: 200px;
}

.dashboard-photo-container {
  display: inline-block;
  padding: 10px;
}

.complianceCheckBox{
  font-size: 50px;
}

.compliance-link {
  text-decoration: underline;
}

.meal {
  background-color: #243841;
}

.activity {
  background-color: #93767a;
}

.metrics {
  background-color: #8fa3ab;
}

.glucose {
  background-color: #593c40;
}

.mood {
  background-color: #456673;
}

.center-data {
  text-align: center;
}

.journal-head {
  color : #fff;
}

.mealLogCover {
  object-fit: cover;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ionLogItem {
  padding-top: 10px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.mood-icon{
  font-size: 35px;
}
