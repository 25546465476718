
.koroQuestionHeaderItem {
    --background: white;
}

.koroQuestionAvatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.koroQuestionCategory {
    color: #58bca4;
}