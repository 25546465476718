.koroMessageBoxItem {
  --background: white;
  --border-radius: 20px;
  --border-color: #838383;
  --border-style: solid;
  --border-width: 1px;
}

.whatToShare {
  font-size: 15px;
  color: #8e8e8e;
}

.koroAskQuestionTitle {
  font-size: 25px;
  font-weight: bold;
}

.koroAddImageLink {
  font-size: 13px;
  color: #007aff;
  text-decoration: underline;
}
