.title {
  font-weight: bold;
  font-size: 20px;
}



.alert-radio-label.sc-ion-alert-md {
  white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
   white-space: pre-line !important;
}
