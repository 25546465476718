.koroInterestTileImg {
  margin: 0 auto;
  margin-top: 10px;
  width: 70px;
  height: 70px;
  
}

.koroInterestTileTitle {
  text-align: center;
  font-size: 15px;
  color: black;
  font-weight: bold;
}

.koroInterestTileQuestions {
  text-align: center;
  font-size: 12px;
}

.koroInterestTile {
  padding: 10px;
  background-color: #e8f3f191;
  border-radius: 20px;
}
