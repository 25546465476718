.upcommingsessions{
    margin-top: 10px;
    
    padding: 10px;
    border: 1px solid #243841;
    border-radius: 10px;
    box-shadow: 5px 5px 20px 10px #caaeb0;
}



ion-split-pane {
    --side-width: 250px;
    --side-max-width: 350px;
  
  }