.todo {
  border-radius: 10px;
  margin: 5px;
}

.need-todo {
  border: 1px solid #3880ff;
}

.todo-title {
  font-size: 50px;
}

.session-time-pretty {
  font-size: 17px;
  color: #000000;
  font-weight: bold;
}

.session-card {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  
}

.session-cancel {
  text-decoration: underline;
  margin-bottom: 5px;
}

.wrapper-my-coach-session {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin: auto;
}

.wrapper-my-coach-session img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.join-session-button
{
font-size: 15px;
}
