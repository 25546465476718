.attachFileLinkButton
{
    /* color: #3a4ce8;
    text-decoration: underline;
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 10px;
    margin-top: 10px; */
    display: none;
}
.attachFileLink
{
    color: #3a4ce8;
    text-decoration: underline;
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 10px;
}