.meetingWindow
{
    border: 1px solid rgb(203, 16, 16);
    height: 500px;
}

.meeting {
    display: grid;
    grid-template-columns: 70% 30%;
    min-height: calc(100vh - 3.5rem);
  }

  /* #callScreen {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 30%;
    top: 3.5rem;
    z-index: 2;
  } */

  #callScreen {
    width: 100%;
    height : 100%;
  }

  .meetingWindow
  {
    height: 600px;
  }